import React from 'react';
import logo from './minecraft.png';
import './App.css';

/*
interface Role {
  name: string;
  colour: string;
}

interface User {
  username: string;
  tag: number;
  avatar: string;
  status: string;
  activity: string;
  activityHours: number;
  roles: Role[];
}*/

function App() {
  const [num, setNum] = React.useState(0);
  React.useEffect(() => {
    if (num === 168) {
      window.location.href = 'https://www.youtube.com/watch?v=iCvRsBEpxPI';
    }
  }, [num])

  return (
    <div className="App">
      <div className="card">
        <div className="top">
          <div className="shade" />
          <div className="avatar">
            <img className="pic" alt="" src="https://cdn.discordapp.com/avatars/530194256082501672/1690ded71941b4dab47e18f6c1d336b1.png?size=256" />
            <div className="status" />
          </div>          
        </div>
        <p className="username">amzluvz<span className="tag">#7567</span></p>
        <div className="divider" />
        <div className="activity mgtop">
          <span className="title">Playing a game</span>
          <div className="game">
            <img className="gamelogo" src={logo} alt="" />
            <div className="gameinfo">
              <p className="gametitle">Minecraft</p>
              <p className="length">{num === 0 ? "Just started playing" : `for ${num} `}{num !== 1 ? num === 0 ? "" : "hours" : "hour"}</p>
            </div>
          </div>
          <button className="more" onClick={() => setNum(prev => prev+1)}>More</button>
        </div>
        <p className="title">Roles</p>
        <div className="roles mgleft">
          <div className="rolered role red">
            <div className="circle circlered" />
            <p className="rolename">VALORANT</p>
          </div>
          <div className="rolegreen role green">
            <div className="circle circlegreen" />
            <p className="rolename">Minecraft</p>
          </div>
          <div className="roleblue role blue">
            <div className="circle circleblue" />
            <p className="rolename">Gamer</p>
          </div>
        </div>
        <div className="note mgtop">
          <p className="title">Note</p>
          <p className="addnote mgleft">The one and only true gamer</p>
        </div>
        <div className="message">&nbsp;Message @amzluvz</div>
        <p className="extra">Right click user for more actions</p>
      </div>
    </div>
  );
}

export default App;
